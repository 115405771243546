.footer {
    background: $footer-bg;
    color: $footer-color;

    .logo {
        display: block;
        img {
            max-width: 300px;
            margin-bottom: $distance * 2;
        }
    }

    .img-certificate {
        max-width: 140px;
    }

    .col-footer {
        .heading {
            color: $footer-heading-color;
            letter-spacing: 0;
            font-size: $footer-heading-font-size;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            margin-bottom: 1rem;
        }
    }

    .nav .nav-item .nav-link,
    .footer-link {
        color: $footer-link-color !important;

        &:hover {
            color: $footer-link-hover-color !important;
        }
    }

    .list-unstyled li a,
    .list-inline li a {
        display: inline-block;
        padding: .125rem 0;
        color: rgba($footer-link-color, .7);
        font-size: $footer-link-font-size;
        font-weight: 700;
        i {
            color: $white;
            padding-right: $distance;
        }

        &:hover {
            color: $footer-link-hover-color;

            .fa-lg {
                color: $primary;
            }
        }

        .fa-lg {
            font-size: 2.33333333em;
            margin-right: $distance * 2;
            margin-top: $distance;
        }
    }

    h6 {
        color: $white;
        font-weight: bold;
    }

    &-copyright {
        background-color: $footer-copyright-bg;
    }

    .copyright {
        font-size: $font-size-sm;
        font-weight: bold;
        padding: $distance * 2 0;
    }
}

.footer-dark {
    .col-footer .heading {
        color: $white;
    }
}

.footer.has-cards {
    overflow: hidden;
    padding-top: 500px;
    margin-top: -420px;
    position: relative;
    background: transparent;
    pointer-events: none;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 600px;
        height: 2000px;
        background: theme-color("secondary");
        transform: skew(0,-8deg);
    }

    .container {
        pointer-events: auto;
        position: relative;
    }
}

.nav-footer {
    .nav-link {
        font-size: $font-size-sm;
    }

    .nav-item:last-child {
        .nav-link {
            padding-right: 0;
        }
    }
}
