// Swiper

.slider-custom {
    .swiper-pagination-bullet-active {
        background-color: $primary;
    }
}


//Nav
.header-global {
    .menu-language {
        img {
            margin-right: $distance / 2;
        }
    }

    .header-search {
        width: 40%;

        input,
        input:focus {
            border-color: $white;
            border-right: 1px solid $primary;
        }

        &--mobile {
            width: 100%;
            input,
            input:focus {
                border-color: $primary;
            }
        }
    }
}

#navbar-main {
    .link-cart {
        .badge {
            border-radius: 50%;
            display: inline-block;
            vertical-align: top;
            margin-top: -$distance / 2;
            width: 20px;
            height: 20px;
            line-height: 20px;
            padding: 0;
            color: $white;
        }
    }
}

//Progress
.progress-wrapper {
    padding-top: 0;
    .progress-info {
        position: absolute;
        width: 38px;
        height: 38px;
        background: $white;
        border: 2px solid $primary;
        border-radius: 50%;
        left: 0;
        top: -16px;
        margin-bottom: 0;
        .progress-percentage {
            margin: 0 auto;
        }
    }
    .progress {
        border-radius: 0;
    }
    .progress-percentage {
        span {
            font-size: 12px;
        }
    }
}

//Product Campaign
.product-item {
    margin-bottom: $distance * 7;

    .bg-product {
        height: 250px;
        background-size: cover;
    }

    .card-body {
        padding-top: 0;
    }
    .card-title {
        font-size: $h5-font-size;
        font-weight: 500;
    }

    &__content {
        margin-bottom: $card-spacer-y;
        hr {
            margin: $distance auto 12px;
            max-width: 80px;
        }
    }

    &__description {
        font-size: $font-size-sm;
    }

    button {
        align-self: center;
        margin-bottom: -21px;
    }
}