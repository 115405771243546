.event-item {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-top: -30px;
    position: relative;
    z-index: 1;
    background: $white;
    padding: 15px 20px 10px;
    h6 {
        color: $primary;

    }

    .fa-star {
        color: #ecba55;
        margin-right: $distance / 2;
    }
    &__bg-img {
        height: 230px;
        background-size: cover;
        margin-bottom: $distance * 2 ;
    }
    &__time-place {
        li {
            i {
                width: 20px;
            }
        }
    }
    &__countdown {
        span {
            width: 50px;
            height: 50px;
            line-height: 50px;
            background-color: $primary;
            color: $white;
            font-size: $h4-font-size;
            font-weight: 800;
            text-align: center;
            display: block;
        }
    }
}

.event-amount {
    padding: 20px 5px 10px;
    &__description {
        font-size: $small-font-size;

        p {
            font-size: 100%;
            font-weight: 400;
            margin-bottom: $distance;
            line-height: 1.5;
        }
    }
}

.event-price-ranger {
    padding-top: 20px;
    &__price {
        color: $primary;
    }

    .input-slider-container {
        margin-bottom: $distance * 10;
    }
}

.list-campaign-featured {
    .btn-outline-primary {
        color: $primary !important;
        &:hover {
            color: $white !important;
        }
    }
}
