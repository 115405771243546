
.headroom {
    will-change: transform;
    background-color: rgba($black, .2);
    @include transition($transition-base);

    &--pinned {
        @extend .position-fixed;
        transform: translateY(0%);
    }

    &--unpinned {
        @extend .position-fixed;
        transform: translateY(-100%);
    }

    &--not-top {
        padding-top: .5rem;
        padding-bottom: .5rem;
        background-color: theme-color("default") !important;
        box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
    }
}
